import React, {useState} from "react";
import {Box, Button, CircularProgress, Container, Stack, TextField} from "@mui/material";
import logo from '../assets/logo.png'
import {auth} from "../firebase/clientApp";
import {useNavigate} from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Login = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')

    const doLogin = async (e) => {
        e.preventDefault()
        setLoading(true)
        await auth.signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                navigate('/', {replace: true})
            })
            .catch((error) => {
                setMessage("C'è stato un errore, si prega di verificare il corretto inserimento delle credenziali e riprovare")
                setError(true)
            });
        setLoading(false)
    }
    return (
        <main style={{height:'max(100vh, 35rem)'}}>
            <Container maxWidth={"sm"} sx={{pt: 6}}>
                <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(!error)}>
                    <Alert onClose={() => setError(!error)} severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
                <Box sx={{width:'min(12rem, 50%)', p: 1, margin: 'auto',mt: 9, mb:6}}>
                    <img src={logo} style={{width: '100%'}}/>
                </Box>
                <Stack spacing={4} component={"form"} onSubmit={doLogin}>
                    <TextField required onChange={(e) => setEmail(e.target.value)} placeholder={'Email'} type={'email'}/>
                    <TextField required onChange={(e) => setPassword(e.target.value)} placeholder={'Password'} type={'password'}/>
                    <Box sx={{textAlign: 'center'}}>
                        {loading ? <CircularProgress/> : <Button size={"large"} type={"submit"} variant={"contained"}>
                            Entra
                        </Button>}
                    </Box>
                </Stack>
            </Container>
        </main>

    )
}
