import {createTheme} from "@mui/material";

export const barillaTheme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides:{
                root:{
                    background: 'linear-gradient(90deg, rgba(21,26,55,1) 0%, rgba(10,2,36,1) 100%)',
                    borderBottom: '2px solid #151a37',
                    borderRadius:'0'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '1rem',
                    background: 'rgba(147,147,147,0.0)',
                    //border: '0.1px solid white',
                },
            },
        },
        MuiTabPanel: {
            styleOverrides:{
                root:{
                    padding: '1rem 0.6rem'
                }
            }
        },
    },
    palette:{
        mode:'dark',
        primary:{
            main:'#b31732'
        },
        background:{
            default:'#1C3775FF'
        },
        error:{
            main:'#fde451'
        },
        warning:{
            main:'#fde451'
        },
        success:{
            main:'#79d8a1'
        }
    }
})

