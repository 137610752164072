import './App.css';
import {Route, Routes} from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import {CssBaseline} from "@mui/material";
import {Login} from "./pages/Login";
import {Streaming} from "./pages/Streaming";

function App() {
    return (
        <div>
            <CssBaseline/>
            <Routes>
                <Route path={'/login'} element={<Login/>}/>
                <Route element={<ProtectedRoute/>}>
                    <Route path={'/'} element={<Streaming/>}/>
                </Route>
            </Routes>
        </div>
    );
}

export default App;
