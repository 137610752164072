import React from "react";
import {Box, Container, Grid, Typography} from "@mui/material";

export const Streaming = () => {
  return(
      <main style={{height:'max(100vh, 45rem)'}}>
          <Container maxWidth={'lg'} sx={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
              {/*<Typography variant={"h3"}>
                  The event will start soon
              </Typography>*/}
              <div
                  style={{position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', width: '100%'}}>
                  <iframe
                      src="https://players.plesh.co/barilla"
                      allowFullScreen
                      style={{
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          border: "none"
                      }}></iframe>
              </div>
          </Container>
      </main>

  )
}
